export default function useFiles() {
  const runtimeConfig = useRuntimeConfig()

  function fileUrl(fileId: string) {
    return `${runtimeConfig.public.directusUrl}/assets/${fileId}?quality=50`
  }

  function editedFileUrl(fileId: string, quality: number, width: number, height: number, fit:string) {
    return `${runtimeConfig.public.directusUrl}/assets/${fileId}?quality=${quality}${fit ? `&fit=${fit}` : ''}${width ? `&width=${width}` : ''}${height ? `&height=${height}` : ''}&transforms=[["resize", {"background": "%23ffffff"}]]&format=webp`
  }

  return {
    fileUrl, editedFileUrl
  }
}
